<template>
  <div class="main gray-bg">
    <van-nav-bar class="top-bar" title="小号管理" left-arrow @click-left="onClickLeft" @click-right="addSubPaneShow=true">
      <template #right>
        <van-icon :name="require('@/assets/images/personal/add-o.png')"/>
      </template>
    </van-nav-bar>
    <van-cell-group class="subList">
      <van-cell class="sub-cell" v-for="item in list" :key="item.id">
        <template #title>
          <div class="van-ellipsis sub-title">{{ item.nickname }}</div>
          <van-icon v-if="item.is_last" :name="require('@/assets/images/personal/sub-tag.png')" class="sub-tag"/>
        </template>
        <template #right-icon>
          <van-icon :name="require('@/assets/images/personal/edit.png')" class="sub-edit" @click="openEditDialog(item.id)"/>
        </template>
      </van-cell>
    </van-cell-group>
    <!--修改小号-->
    <van-dialog :show="editSubPaneShow" title="需改小号昵称" @confirm="editSmallEvent" @cancel="closeEditDialog" :confirmButtonDisabled ="isDisabledEditConfirm" show-cancel-button>
      <van-cell-group class="border-field-group">
        <validator-field v-model="editData.nickname" :validator="smallNickNameRules" placeholder="小号名称不得大于12个字符" @validSuccess="isDisabledEditConfirm = false" @validFail="isDisabledEditConfirm = true"/>
      </van-cell-group>
    </van-dialog>
    <!--修改小号-->
    <!--添加小号-->
    <van-dialog :show="addSubPaneShow" title="添加小号" show-cancel-button @confirm="addSmallEvent" @cancel="addSubPaneShow=false" :confirmButtonDisabled ="isDisabledAddConfirm">
      <van-cell-group class="border-field-group">
        <validator-field v-model="addSmallNickName" :validator="smallNickNameRules" placeholder="小号名称不得大于12个字符" @validSuccess="isDisabledAddConfirm = false" @validFail="isDisabledAddConfirm = true"/>
      </van-cell-group>
    </van-dialog>
    <!--修改小号-->
  </div>
</template>

<script>
import {NavBar, Cell, CellGroup, Icon, Dialog, Field, Toast} from "vant";
import "@/style/common.css"
import {addSmall, editSmall, smallList} from "@/api/user";
import {reactive, ref} from "vue";
import ValidatorField from "@/components/ValidatorField";
import {onClickLeft} from "@/utils/function"

export default {
  name: "PersonalSubList",
  components: {
    ValidatorField,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    [Icon.name]: Icon
  },
  setup() {
    //初始化
    const list = ref([]);
    const getSubList = () => {
      Toast.loading({
        message: "加载中...",
        overlay: true,
        duration: 500,
        forbidClick: true,
      });
      smallList().then((res) => {
        list.value = res.data
      });
    }
    //初始获取小号列表
    getSubList()
    //添加小号
    //小号操作
    const smallNickNameRules = reactive({
      label: '小号名称',
      rules: 'required;length(~12,true)'
    })
    const addSubPaneShow = ref(false)
    const isDisabledAddConfirm = ref(true)
    const addSmallNickName = ref('')
    const addSmallEvent = () => {
      addSmall(addSmallNickName.value).then((res) => {
        Toast(res.message)
        addSubPaneShow.value = false
        getSubList()
      })
    };
    //编辑小号
    const editSubPaneShow = ref(false)
    const isDisabledEditConfirm = ref(true)
    const editData = reactive({
      nickname:'',
      small_id:0
    })
    //打开小号编辑弹窗
    const openEditDialog = (ids) => {
      editSubPaneShow.value = true
      editData.small_id = ids
    };
    //关闭小号编辑弹窗
    const closeEditDialog = () => {
      editSubPaneShow.value = false
      editData.small_id = 0
    };
    //小号编辑确认提交
    const editSmallEvent = () => {
      editSmall(editData).then((res)=>{
        Toast(res.message)
        editSubPaneShow.value = false
        getSubList()
      })
    };
    return {
      list,
      addSubPaneShow,
      addSmallNickName,
      editData,
      editSubPaneShow,
      smallNickNameRules,
      isDisabledAddConfirm,
      isDisabledEditConfirm,
      onClickLeft,
      addSmallEvent,
      openEditDialog,
      editSmallEvent,
      closeEditDialog,
    }
  }
}
</script>

<style scoped>
.top-bar :deep(.van-nav-bar__right) {
  font-size: 24px;
}

.subList {
  background: none
}

.subList:after {
  display: none;
}

.sub-cell {
  height: 40px;
  line-height: 40px;
  background: #ECF2FF;
  padding: 0px 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.sub-cell :deep(.van-cell__title) {
  display: flex;
  height: 40px;
  line-height: 40px;
  align-items: center;
  text-align: left;
  flex: auto;
  max-width: 300px;
}

.sub-title {
  font-size: 13px;
  font-weight: 500;
  color: #4E5969;
}

.sub-tag {
  flex: 1;
  font-size: 58px;
  margin-left: 5px;
}

.sub-edit {
  font-size: 24px;
}
</style>
